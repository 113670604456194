import React from 'react'
import { graphql } from 'gatsby'
import ScrollAnimation from 'react-animate-on-scroll';
import SEO from "../components/seo"
import loadable from '@loadable/component';
import LayoutTwo from '../components/layoutTwo';
import { Container, Row, Col } from 'react-bootstrap';

const BreadcrumbModule = loadable(() => import("../components/BreadcrumbModule/BreadcrumbModule"));
const StaticIntro = loadable(() => import("../components/StaticIntro/StaticIntro"));
const ValuationCard = loadable(() => import("../components/ValuationCard/ValuationCard"));
const ValuationContact = loadable(() => import("../components/ValuationContact/ValuationContact"));

const ValuationLanding = ({ data }, props) => {
    const PageData = data?.strapiPage
    return (
        <LayoutTwo popularSearch={PageData?.select_popular_search?.title}>
            <div className="layout-padding-top"></div>

            <BreadcrumbModule subparentlabel={PageData.choose_menu[0]?.strapi_parent?.title} subparentlink={PageData.choose_menu[0]?.strapi_parent?.slug} parentlabel={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.title} parentlink={PageData.choose_menu[0]?.strapi_parent?.strapi_parent?.slug} title={PageData.title} />

            <Container>
                <Row>
                    <Col xl={7}>
                        {PageData?.Add_Page_Modules?.length > 0 && PageData.Add_Page_Modules?.map((module, index) => {
                            return (
                                <>
                                    {module.strapi_component === "page-modules.plain-content" &&
                                        <StaticIntro {...module} tag="inner-large" />
                                    }
                                    {module.strapi_component === "page-modules.valuation-module" &&
                                        <div className="valuation-card-wrapper-main">
                                            {module.add_details && module.add_details.map((item, i) => {
                                                return (
                                                    <ScrollAnimation animateIn="animate__slideInUp" animateOnce delay={(i+1)*300} offset={50}>
                                                        <ValuationCard valuationDetail={item} key={i} />
                                                    </ScrollAnimation>
                                                )
                                            })
                                            }
                                        </div>
                                    }
                                </>
                            )
                        }
                        )}
                    </Col>
                    <Col xl={1}></Col>

                    <Col xl={4}>
                        <ValuationContact />
                    </Col>
                </Row>
            </Container>
        </LayoutTwo>
    )
}


export const Head = ({ data }) => {
    const PageData = data?.strapiPage
    return (
        <SEO title={PageData?.seo?.metaTitle ? PageData?.seo?.metaTitle : PageData?.title} description={PageData?.seo?.metaDescription ? PageData?.seo?.metaDescription : PageData?.title} />
    )
}

export default ValuationLanding

export const query = graphql`
query ($page_id: String) {
    strapiPage(id: {eq: $page_id}) {
        ...PageFragment
        Add_Page_Modules {
            ... on STRAPI__COMPONENT_PAGE_MODULES_VALUATION_MODULE {
              ...ValuationModuleFragment
            }
            ... on STRAPI__COMPONENT_PAGE_MODULES_PLAIN_CONTENT {
              ...PlainContentFragment
            }
        }
    }
  }
`